<template>
  <BCard
    :class="{
    'mb-2': true,
    'question': true,
    'answered-question': isClosedState,
    'not-answered-question': !haveAnswer,
    'cursor-pointer' : true
    }"
    no-body
    @click="toggleState"
  >
    <BCardBody
        ref="cardBody"
    >
      <div class="d-flex align-items-center">
        <div
            ref="cardContent"
            style="width: 100%"
        >
          <h6
              ref="cardTitle"
              style="width: 100%"
              :class="{ 'mb-2': !isClosedState, 'mb-0': isClosedState }"
          >
            {{ item.name }}
          </h6>
          <div
              v-if="item.description && !isClosedState"
              class="mb-2"
              v-html="item.description"
          />

          <div
              v-if="!isClosedState"
              class="mb-2"
          >
            <template v-if="item.answer_type === 'radio'">
              <div
                  v-for="option in item.answer_options"
                  :key="option"
              >
                <div
                    class="question-checkbox gap-1"
                    @click="value = item.answer_options.indexOf(option)"
                >
                  <BFormRadio
                      v-model="value"
                      :name="String(item.id)"
                      switch
                      :value="item.answer_options.indexOf(option)"
                  />
                  {{ option }}
                </div>
              </div>
            </template>

            <template v-if="item.answer_type === 'switch'">
              <BFormCheckbox
                  v-model="value"
                  class="mb-2"
                  :name="String(item.id)"
                  switch
              />
            </template>

            <template v-if="item.answer_type === 'checkbox'">
              <div
                  v-for="option in item.answer_options"
                  :key="option"
              >
                <div
                    class="question-checkbox gap-1"
                    @click.self="setCheckboxValue(item.answer_options.indexOf(option))"
                >
                  <BFormCheckbox
                      v-model="value"
                      :name="String(item.id)"
                      :value="item.answer_options.indexOf(option)"
                  />
                  {{ option }}
                </div>
              </div>
            </template>

            <template v-if="item.answer_type === 'text'">
              <BFormTextarea
                  v-model="value"
                  placeholder="Текстовое поле"
              />
            </template>

            <template v-if="item.answer_type === 'number'">
              <BFormInput
                  v-model="value"
                  type="number"
                  placeholder="Введите ответ цифрой"
              />
            </template>

            <template v-if="item.answer_type === 'date'">
              <Calendar
                  :value="calendarValue()"
                  icon="CalendarIcon"
                  :start-weekday="1"
                  time
                  :with-label="false"
                  :right="isMobile"
                  :past="false"
                  :return-object="!item.id"
                  @updateField="closeCalendarDropdown"
              />
            </template>

            <template v-if="item.answer_type === 'file'">
              <div class="file">
                <BFormFile
                    v-model="rawFile"
                    class="fileInput"
                    multiple
                    placeholder="Загрузить с устройства"
                    drop-placeholder="Поместите файлы сюда или нажмите, чтобы загрузить"
                    accept=".png, .jpeg, .gif, .jpg, .mp4, .avi, .mov"
                    @input="onInputFiles"
                />
                <div class="filePreview">
                  <template v-if="item.answer && item.answer.length > 0">
                    <feather-icon
                        style="cursor: pointer;"
                        icon="ImageIcon"
                        size="18"
                        :badge="item.answer.length"
                        @click="openImage(item.answer)"
                    />
                  </template>
                </div>
              </div>
            </template>
          </div>

          <template v-if="!isClosedState">
            <BButton
                v-if="!inStepper"
                variant="primary"
                :disabled="isLoaded"
                @click="$emit('answered', { answer: value, checklistItemId: item.id, isFormData })"
            >
              Подтвердить
            </BButton>
            <div
                v-else
                class="gap-2 d-flex"
            >
              <BButton
                  v-if="lastStep !== step"
                  class="px-4"
                  variant="primary"
                  :disabled="nextButtonDisabled()"
                  @click="$emit('stepNext',
                  { answer: value, checklistItemId: item.id, isFormData })"
              >
                Далее
              </BButton>
              <BButton
                  v-else
                  variant="success"
                  :disabled="nextButtonDisabled()"
                  @click="$emit('end', { answer: value, checklistItemId: item.id, isFormData })"
              >
                Завершить
              </BButton>
            </div>
          </template>
        </div>
        <div
            v-if="isClosedState"
            ref="cardAnswer"
            class="text-muted text-right"
            style="min-width: 100px"
        >
          <template
              v-if="item.answer_type === 'radio' && item.answer !== null"
          >
            {{ radioValue(item) }}
          </template>
          <template
              v-if="item.answer_type === 'switch'"
          >
            <template v-if="item.answer === true || item.answer === false">
              {{ item.answer ? 'Да' : 'Нет' }}
            </template>
          </template>
          <template
              v-if="item.answer_type === 'checkbox' && item.answer"
          >
            {{ checkboxValue(item) }}
          </template>
          <template
              v-if="item.answer_type === 'number' && item.answer"
          >
            {{ item.answer }}
          </template>
          <template
              v-if="item.answer_type === 'date' && item.answer"
          >
            {{ formatedDate(item.answer) }}
          </template>
          <template
              v-if="item.answer_type === 'text' && item.answer"
          >
            <template
                v-if="String(item.answer).length > 50"
            >
              <feather-icon
                  ref="cardAnswerText"
                  style="cursor: pointer;"
                  icon="MessageSquareIcon"
                  size="18"
                  @click="openText(item.answer)"
              />
            </template>
            <template
                v-else-if="String(item.answer).length <= 50"
            >
              {{ item.answer }}
            </template>
          </template>
          <template
              v-else-if="item.answer_type === 'file'
              && item.answer && item.answer.length > 0"
          >
            <feather-icon
                ret="cardAnswerFile"
                style="cursor: pointer;"
                icon="ImageIcon"
                size="18"
                :badge="item.answer.length"
                @click="openImage(item.answer)"
            />
          </template>
        </div>
      </div>
      <b-modal
          ref="fileModalRef"
          v-model="fileModal"
          size="lg"
          title="Просмотр файлов"
      >
        <template #modal-footer="{}">
          <BButton @click="$refs['fileModalRef'].hide()">
            Закрыть
          </BButton>
        </template>
        <div class="fileModalImages">
          <b-carousel
              v-model="slide"
              controls
              label-next="''"
              label-prev="''"
              :interval="400000"
              style="width: 100%"
              indicators
          >
            <b-carousel-slide
                v-for="(file, index) in fileModalValue"
                :key="index"
                style="padding-bottom: 40px"
            >
              <template #img>
                <div class="d-flex flex-column align-items-center">
                  <img
                      v-if="isImage(file.extension)"
                      :src="file.url"
                      style="width: 60%; max-height: 50vh"
                  >
                  <video
                      v-if="isVideo(file.extension)"
                      :src="file.url"
                      style="width: 60%; max-height: 50vh"
                      controls
                  />
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </b-modal>

      <b-modal
          ref="textModalRef"
          v-model="textModal"
          size="lg"
          title="Просмотр текста"
      >
        <template #modal-footer="{}">
          <BButton @click="$refs['textModalRef'].hide()">
            Закрыть
          </BButton>
        </template>
        <div
            class="textModal"
            style="text-align: justify"
        >
          {{ textModalValue }}
        </div>
      </b-modal>
    </BCardBody>
  </BCard>
</template>

<script>
import {
  BCard,
  BFormCheckbox,
  BFormRadio,
  BButton,
  BFormTextarea,
  BFormInput,
  BFormFile,
  BModal,
  BCarousel,
  BCarouselSlide,
  BCardBody,
} from 'bootstrap-vue';
import {
  toRefs, ref, computed,
} from '@vue/composition-api';
import moment from "moment/moment";
import {formatDate} from "@/utils/time";
import fileTypeByExt from "@/utils/fileTypeByExt";
import {axiosIns} from "@/store/api";
import {useStore} from "@/hooks/useStore";
import Calendar from '@/components/fields/calendar/index.vue';
import {useToast} from "@/hooks/useToast";
import {useI18n} from "@/hooks/useI18n";

export default {
  components: {
    BCard,
    BFormCheckbox,
    BButton,
    BFormRadio,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BModal,
    BCarousel,
    BCarouselSlide,
    BCardBody,
    Calendar,
  },
  props: {
    item: Object,
    inStepper: Boolean,
    step: Number,
    lastStep: Number,
    isOpen: Boolean,
    disabledStepButton: Number,
  },
  setup(props, { emit }) {
    const { item, isOpen, disabledStepButton, step, inStepper } = toRefs(props);
    const { t } = useI18n();
    const value = ref();
    const isFormData = ref(false);
    const isClosedState = computed(() => !isOpen.value);
    const haveAnswer = ref(item.value?.answer !== null);
    const isMobile = ref(false);
    const store = useStore();
    const { successToast, dangerToast } = useToast();
    const cardBody = ref(null);
    const cardTitle = ref(null);
    const cardAnswer = ref(null);
    const cardAnswerFile = ref(null);
    const cardAnswerText = ref(null);
    const cardContent = ref(null);

    isMobile.value = store.state.app.isMobile;

    const toggleState = (el) => {
      console.log(el.target, cardBody.value);
      if (el.target !== cardBody.value && el.target !== cardTitle.value
          && el.target !== cardAnswer.value && el.target !== cardAnswerFile
          && el.target !== cardAnswerText.value && el.target !== cardContent.value) {
        return;
      }
      if (isClosedState.value === false) {
        emit('closeItem', item.value.id);
      } else {
        emit('openItem', item.value.id);
      }
    };

    const defaultValue = ref(null);
    switch (item.value.answer_type) {
      case 'checkbox':
        value.value = [];
        break;
      case 'switch':
        value.value = false;
        break;
      default:
        value.value = null;
        break;
    }
    defaultValue.value = value.value;

    value.value = item.value?.answer || defaultValue.value;

    const calendarValue = () => {
      if (!value.value) {
        value.value = moment().toISOString();
      }
      return value.value;
    };

    const closeCalendarDropdown = async (data) => {
      value.value = data.value;
    };

    const filePreviews = ref([]);
    const getPreviewUrl = (file) => {
      filePreviews.value.push(URL.createObjectURL(file));
    };

    const setCheckboxValue = (newValue) => {
      if (!Array.isArray(value.value)) {
        value.value = [newValue];
        return;
      }
      if (value.value.indexOf(newValue) === -1) {
        value.value.push(newValue);
      } else {
        value.value.splice(value.value.indexOf(newValue), 1);
      }
    };

    const isLoaded = ref(false);

    const onInputFiles = async (files) => {
      isLoaded.value = true;
      const formData = new FormData();
      const fData = new FormData();
      filePreviews.value = [];
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const filename = file.name;
        formData.append('files[]', file, filename);
        getPreviewUrl(file);
      }
      await axiosIns.post('tools/upload-files', formData)
          .then((res) => {
            const { files } = res.data;
            files.forEach((el) => {
              fData.append('answer[]', el.id);
            });
            successToast(t('file_upload.message.success'));
          })
          .catch(() => {
            dangerToast(t('file_upload.message.error'));
          });
      isFormData.value = true;
      value.value = fData;
      isLoaded.value = false;
    };
    const rawFile = ref();

    const radioValue = (element) => {
      if (!element.answer_options
          || element.answer_options.length === 0
          || element.answer === null) {
        return '';
      }
      // eslint-disable-next-line radix,implicit-arrow-linebreak
      return element.answer_options[parseInt(element.answer)];
    };

    const checkboxValue = (element) => {
      if (!element.answer || element.answer.length === 0) {
        return '';
      }

      const answersArray = [];
      element.answer.forEach((el) => {
        // eslint-disable-next-line radix
        answersArray.push(element.answer_options[parseInt(el)]);
      });

      return answersArray.join(', ');
    };

    moment.locale('ru');

    const formatedDate = (value) => formatDate(value, {
      withYear: true,
      withHours: true,
      withMinutes: true,
    });

    const textModalValue = ref(null);
    const textModal = ref(false);
    const openText = (answer) => {
      textModal.value = true;
      textModalValue.value = answer;
    };

    const slide = ref(0);
    const fileModalValue = ref(null);
    const fileModal = ref(false);
    const {getFileType} = fileTypeByExt();
    const openImage = (answer) => {
      fileModal.value = true;
      fileModalValue.value = answer;
    };

    const nextButtonDisabled = () => {
      if (inStepper.value
          && step.value > disabledStepButton.value
          && disabledStepButton.value !== null) {
        return true;
      }

      if (isLoaded.value) {
        return true;
      }

      if (!item.value.can_not_skip) {
        return false;
      }

      if (item.value.answer_type === 'checkbox') {
        return !value.value?.length;
      }

      return item.value.answer === null
          && (value.value === null || value.value === '');
    };

    const fileType = (ext) => getFileType(ext);
    const isImage = (ext) => fileType(ext) === 'image';
    const isVideo = (ext) => fileType(ext) === 'video';

    return {
      value,
      closeCalendarDropdown,
      onInputFiles,
      rawFile,
      isFormData,
      isClosedState,
      toggleState,
      filePreviews,
      radioValue,
      checkboxValue,
      formatedDate,
      textModalValue,
      textModal,
      openText,
      slide,
      fileModal,
      fileModalValue,
      openImage,
      isImage,
      isVideo,
      isLoaded,
      isMobile,
      calendarValue,
      nextButtonDisabled,
      haveAnswer,
      setCheckboxValue,
      cardBody,
      cardTitle,
      cardAnswer,
      cardAnswerFile,
      cardAnswerText,
      cardContent,
    };
  },
};
</script>

<style lang="sass">
.question-checkbox
  border: 1px solid #EBE9F1
  border-radius: 6px
  padding: 14px
  margin-bottom: 14px
  display: flex
  align-items: center
.answered-question
  border-left: 6px solid #28C76F
  cursor: pointer
.not-answered-question
  border-left: 6px solid #EBE9F1
  cursor: pointer
.question
  & .file
    display: flex
    flex-direction: column
  & .filePreview
    flex: 2
    margin-top: 16px
    margin-bottom: 8px
    justify-content: flex-start
    display: flex
    gap: 8px
    & img
      max-width: 100%
      border-radius: 8px
      overflow: hidden
      max-width: 25%
  & .fileInput
    flex: 1
</style>
