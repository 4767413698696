<template>
  <div>
    <h4 class="mb-2 d-flex justify-content-between">
      <div>{{ checklist.name }}</div>
      <div v-if="checklist.is_stepper">
        {{ step + 1 > progressMax ? step : step + 1 }}/{{ progressMax }}
      </div>
      <div v-else>
{{ progress }}/{{ progressMax }}
</div>
    </h4>
    <div class="mb-2">
      <b-progress
        :value="checklist.is_stepper ? step + 1 : progress"
        :max="progressMax"
        :variant="checklist.finished ? 'success' : 'primary'"
        height="8px"
      />
    </div>
    <!-- <div class="mb-2">
      {{ item.description }}
    </div> -->
<!--    <div v-if="!signDisplay">-->
<!--      <template v-for="(checklistItem, index) in checklist.checklist_items">-->
<!--        <WorkChecklistItemQuestion-->
<!--            v-if="!checklist.is_stepper || index === step"-->
<!--            :key="checklistItem.id"-->
<!--            :item="checklistItem"-->
<!--            :in-stepper="!!checklist.is_stepper"-->
<!--            :step="index"-->
<!--            :last-step="checklist.checklist_items.length - 1"-->
<!--            @stepBack="onStepBack"-->
<!--            @stepNext="onStepNext"-->
<!--            @answered="onAnswered"-->
<!--            @end="onEnd"-->
<!--        />-->
<!--      </template>-->

<!--      <div v-if="!checklist.is_stepper && !checklist.finished">-->
<!--        <BButton-->
<!--            size="lg"-->
<!--            :disabled="!completable"-->
<!--            class="mt-1 mb-1"-->
<!--            variant="success"-->
<!--            @click="finish"-->
<!--        >-->
<!--          Завершить-->
<!--        </BButton>-->
<!--        <div-->
<!--            v-if="!completable"-->
<!--            class="mb-2 text-danger"-->
<!--        >-->
<!--          Завершить работу возможно после полного прохождения чек-листа-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="!signDisplay">
      <template v-for="(checklistItem, index) in checklist.checklist_items">
        <WorkChecklistItemQuestion
          :key="checklistItem.id"
          :item="checklistItem"
          :is-open="checklistItem.id === openedId"
          :in-stepper="!!checklist.is_stepper"
          :step="index"
          :last-step="checklist.checklist_items.length - 1"
          :disabled-step-button="disableStepButton()"
          @openItem="openItem"
          @closeItem="closeItem"
          @stepBack="onStepBack"
          @stepNext="onStepNext"
          @answered="onAnswered"
          @end="onEnd"
        />
      </template>

      <div v-if="!checklist.is_stepper && !checklist.finished">
        <BButton
          size="lg"
          :disabled="!completable"
          class="mt-1 mb-1"
          variant="success"
          @click="finish"
        >
          Завершить
        </BButton>
        <div
          v-if="!completable"
          class="mb-2 text-danger"
        >
          Завершить работу возможно после полного прохождения чек-листа
        </div>
      </div>
    </div>
    <BCard v-else>
      <h4>Подпись клиента</h4>
      <Sign
        class="mt-2"
        :value="signModel"
        :price="request.price"
        @signed="onSigned"
      />
      <div class="mt-2">
        <BButton
          :disabled="!signTriggered"
          variant="primary"
          @click="signSubmit"
        >
          Завершить
        </BButton>
      </div>
    </BCard>
  </div>
</template>
<script>
import {BButton, BCard, BProgress} from 'bootstrap-vue';
import {computed, ref, toRefs} from '@vue/composition-api';
import {useStore} from '@/hooks/useStore';
import {useToast} from '@/hooks/useToast';
import {useI18n} from '@/hooks/useI18n';
import WorkChecklistItemQuestion from './WorkChecklistItemQuestion.vue';
import Sign from '../Sign.vue';

export default {
  components: {
    BProgress,
    BButton,
    WorkChecklistItemQuestion,
    BCard,
    Sign,
  },
  props: {
    item: Object,
    request: Object,
  },
  setup(props, { emit }) {
    const { successToast, dangerToast } = useToast();
    const { t } = useI18n();
    const store = useStore();
    const { item: checklist, request } = toRefs(props);
    const progressMax = computed(() => checklist.value.checklist_items?.length);
    const openedId = ref(checklist.value.checklist_items[0].id);

    const progress = computed(
      () => {
        const pgs = checklist.value.checklist_items?.filter((i) => i.answer !== null).length + 1;
        if (pgs > progressMax.value) {
          return progressMax.value;
        }
        return pgs;
      }
    );

    const openItem = (val) => {
      openedId.value = val;
    };
    const closeItem = () => {
      openedId.value = null;
    };

    const stepperRequiredCards = computed(() => {
      const requiredArray = checklist.value.checklist_items.filter(
          (el) => el.answer === null && el.can_not_skip === 1
      );
      return requiredArray.map((el) => ({step: checklist.value.checklist_items.indexOf(el)}));
    });
    const disableStepButton = () => {
      if (stepperRequiredCards.value.length > 0) {
        return stepperRequiredCards.value[0].step;
      }

      return null;
    };

    const onAnswered = async ({ answer, checklistItemId, isFormData }) => {
      const checklistItems = checklist.value.checklist_items
          .filter((el) => el.id === checklistItemId);
      if (checklistItems && checklistItems.length) {
        const checklistItem = checklistItems[0];
        if (checklistItem.answer_type === 'checkbox'
            && !checklistItem.can_not_skip
            && answer.length === 0
        ) {
          // eslint-disable-next-line no-param-reassign
          answer = null;
        }
      }
      let response;
      if (isFormData) {
        const formData = answer;
        formData.append('checklist_item_id', checklistItemId);
        response = await store.dispatch('requests/setAnswerFormData', {
          id: request.value.id,
          formData,
        });
      } else {
        response = await store.dispatch('requests/setAnswer', {
          id: request.value.id,
          checklistItemId,
          answer,
        });
      }
      if (response.result) {
        emit('needToUpdate');
        successToast(t('settings.messageUpdated.title'), t('settings.messageUpdated.text'));
        const index = checklist.value.checklist_items.indexOf(
            checklist.value.checklist_items.filter((el) => el.id === checklistItemId)[0]
        );
        if (index + 1 < checklist.value.checklist_items.length) {
          openItem(checklist.value.checklist_items[index + 1].id);
        } else {
          closeItem();
        }
        return true;
      }
      dangerToast(t('errors.something-went-wrong.text'), t('errors.message.updateFailedMessage'));
      return false;
    };

    const step = ref(0);
    if (checklist.value.is_stepper) {
      step.value = checklist.value.checklist_items.filter((i) => !!i.answer).length;
      if (progressMax.value <= step.value) {
        step.value = progressMax.value - 1;
      }
    }
    const onStepNext = async ({ answer, checklistItemId, isFormData }) => {
      if (answer && answer[0] && typeof answer[0] === 'object' && 'url' in answer[0]) {
        if (step.value !== checklist.value.checklist_items.length - 1) step.value += 1;
        return;
      }
      const answered = await onAnswered({ answer, checklistItemId, isFormData });
      if (!answered) return;
      if (step.value !== checklist.value.checklist_items.length - 1) step.value += 1;
    };
    const onStepBack = () => {
      if (step.value !== 0) {
        step.value -= 1;
        openItem(checklist.value.checklist_items[step.value].id);
      }
    };
    const completable = computed(() => {
      const noSkippableFields = checklist.value.checklist_items.filter((i) => i.can_not_skip);
      const notAnsweredNoSkippableFields = noSkippableFields.filter((i) => i.answer === null);
      return !notAnsweredNoSkippableFields.length;
    });

    const closeChecklist = () => {
      emit('needToUpdate');
      emit('closeChecklist');
    };

    const signTriggered = ref(false);
    const signDisplay = ref(false);
    const signModel = ref(null);
    const signSubmit = async () => {
      const { result: signResult } = await store.dispatch('requests/sign', { requestId: request.value.id, sign: signModel.value });
      if (signResult) {
        const { result } = await store.dispatch('requests/closeChecklist', { requestId: request.value.id, checklistId: checklist.value.id });
        if (result) {
          closeChecklist();
        }
      }
    };
    const finish = async () => {
      const {result, data} = await store.dispatch('requests/closeChecklist', {
        requestId: request.value.id,
        checklistId: checklist.value.id
      });
      if (!result) {
        return;
      }
      const newChecklist = data.checklists.filter((el) => el.id === checklist.value.id);
      if (newChecklist && newChecklist.length) {
        emit('needToUpdate');
      }
      if (data.checklists.filter((el) => el.finished === null).length) {
        return;
      }
      if (!request.value.client_sign_required) {
        const { result: finishResult, data: finishData } = await store.dispatch('requests/finish', { requestId: request.value.id });
        if (finishResult) {
          request.value.status = finishData.status;
          request.value.status_id = finishData.status_id;
        }
        closeChecklist();
      }
    };
    const onEnd = async ({ answer, checklistItemId, isFormData }) => {
      await onStepNext({ answer, checklistItemId, isFormData });
      finish();
    };
    const onSigned = async (sign) => {
      signModel.value = sign;
      signTriggered.value = true;
    };

    return {
      progress,
      progressMax,
      step,
      onStepNext,
      onStepBack,
      onAnswered,
      completable,
      signTriggered,
      finish,
      signDisplay,
      signModel,
      onSigned,
      signSubmit,
      onEnd,
      checklist,
      openedId,
      openItem,
      closeItem,
      stepperRequiredCards,
      disableStepButton,
    };
  },
};
</script>
